export default {
    titleTemplate: '%s — Bijlesplein',
    defaultTitle: 'Bijlesplein',
    description: 'Vergroot kennisoverdracht en verklein kansenongelijkheid met Bijlesplein.',
    canonical: 'https://bijlesplein.nl',
    openGraph: {
        type: 'website',
        locale: 'nl_NL',
        url: 'https://bijlesplein.nl',
        site_name: 'Bijlesplein',
        title: 'Bijles voor en door eigen leerlingen of studenten — Bijlesplein',
        description: 'Vergroot kennisoverdracht en verklein kansenongelijkheid. Breng vraag en aanbod van bijles samen, onder regie van onderwijsinstellingen.',
        images: [
            {
                url: 'https://bijlesplein.nl/images/seo/opengraph.png',
                width: 1200,
                height: 630,
                alt: 'Bijlesplein',
            },
        ],
    },
};
